<template>
  <div class="content">
    <div class="about">
      <div @click="checkout(0)">
        <p :class="tablecheckout == 0 ? 'abouthead' : ''">未使用</p>
        <p v-show="tablecheckout == 0"></p>
      </div>
      <div @click="checkout(1)">
        <p :class="tablecheckout == 1 ? 'abouthead' : ''">已使用</p>
        <p v-show="tablecheckout == 1"></p>
      </div>
      <div @click="checkout(2)">
        <p :class="tablecheckout == 2 ? 'abouthead' : ''">已过期</p>
        <p v-show="tablecheckout == 2"></p>
      </div>
    </div>
    <template v-if="list.length>0">
      <div class="list" v-for="(item,index) in list" :key="index" :style="tablecheckout == 0?'':'background:url(https://ppyos.xijiuyou.com/202203/card/gift-bg-img.png) no-repeat;background-size:100%;'">
        <div :style="tablecheckout != 0?'color:rgba(106, 106, 106, 1);':''" v-html="item.desc"></div>
        <div>
            <p>{{item.title}}</p>
            <p>{{ item.note }}</p>
            <p>{{dayjstime(item.expiredTime)}}到期</p>
        </div>
        <div :style="tablecheckout != 0?'background:rgba(0,0,0,0);':''" @click="golink(item.link)">{{tablecheckout == 0?"去使用":''}}</div>
        <img v-if="tablecheckout == 1" class="listy" src="https://ppyos.xijiuyou.com/202203/card/used-tag-img.png" alt="" />
        <img v-if="tablecheckout == 2" class="listg" src="https://ppyos.xijiuyou.com/202203/card/expired-icon.png" alt="" />
      </div>
    </template>
    
    <div class="jiangp" v-if="list.length<1">
        <img src="https://ppyos.xijiuyou.com/202203/card/empyt-img.png" alt="" />
        <p>还未获得卡券哦~</p>
    </div>
  </div>
</template>
  <script>
  import welfare from "../../api/welfare";
  import dayjs from 'dayjs';
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        tablecheckout: 0,
        list: [],
        page:1,
      };
    },
    scrollPage() {
    },
    mounted() {
      const that = this;
      this.myBonus(0);
      window.addEventListener("scroll",function(){
      //加载更多
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
        //写后台加载数据的函数
        that.page ++;
        that.myBonus(that.tablecheckout);
      }
    });
    },
    methods: {
      golink(link){
        window.location.href = link;
      },
      myBonus(status){
        const data={
            status,
            page:this.page,
            pageSize:20
        }
        welfare.myBonus(data).then(res=>{
          this.list.push(...res.list);
        })
      },
      dayjstime(item){
          return dayjs(item).format("YYYY-MM-DD"); 
      },
      checkout(index) {
        this.tablecheckout = index;
        this.page = 1;
        this.list = [];
        this.myBonus(index);
      },
    },
  };
  </script>
  <style  lang="less" scoped>
  .content {
    width: 100vw;
    min-height: 100vh;
    background:#ffffff;
  }
  .about {
    display: flex;
    justify-content: space-around;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
    padding-top: 10px;
  }
  .about div .abouthead:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 42, 42, 1);
  }
  .about div p:nth-child(2) {
    width: 20px;
    height: 4px;
    background: #ff5675;
    border-radius: 2px;
    margin: 0 auto;
    margin-top: 5px;
  }
  .bottomlist {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 30px;
  }
  .list{
    width:347px;
    height:82px;
    display:flex;
    justify-content: space-between;
    margin:0 auto;
    background:url("https://ppyos.xijiuyou.com/202203/card/gift-pink-bg-img.png") no-repeat;
    background-size:100%;
    align-items: center;
    padding:0 12px 0 20px;
    margin-top:10px;
    position: relative;
    .listy{
      position: absolute;
      width: 42px;
      right: 0;
      bottom: 0;
    }
    .listg{
      position: absolute;
      width: 82px;
      right: 0;
      bottom: 7px;
    }
    div:nth-child(1){
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF2A2A;
        width: 50px;
        text-align: center;
        span{
            font-size: 25px;
            font-family: DINOffcPro-Bold, DINOffcPro;
            font-weight: bold;
            color: #FF2A2A;
        }
    }
    div:nth-child(2){
        width: 140px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        p:nth-child(1){
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #444444;
        }
        p{
          white-space:nowrap;
          line-height: 18px;
        }
    }
    div:nth-child(3){
        width: 54px;
        height: 24px;
        background: linear-gradient(270deg, #FF9E9E 0%, #FF6767 100%);
        border-radius: 13px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 24px;
    }
  }
  .jiangp{
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        img{
            margin-top: 180px;
            width: 134px;
        }
        p{
            margin-top: 15px;
        }
    }
  </style>
  <style>
  .list .money{
    font-size: 25px;
font-family: DINOffcPro-Bold, DINOffcPro;
font-weight: bold;
    }
</style>
  
  